import Vuex from 'vuex';
import Vue from 'vue';
import axios from "axios";

Vue.use(Vuex);

let apiUrl = process.env.VUE_APP_API_URL;
const defaultState = () => {
    return {
        loginCredentials: {},
        accountSummary: {},
        accountDetails: [],
        invoiceDetails: [],
        resourceUrl: "",
        isAuthenticated: false,
        serverViewKeyUrl: "https://cportal.hellermann.co.za/doclink/v2?key=",
        loader: false,
        sessionId: "",
        accountNumber: "",
        statementDate: "",
        errorMessage: "",
    }
};

const store = new Vuex.Store({
    state: defaultState,

    getters: {
        getServerViewKeyUrl(state) {
            return state.serverViewKeyUrl;
        },

        getLoginCredential(state) {
            return state.loginCredentials;
        },

        getAccountSummary(state) {
            return state.accountSummary;
        },

        getAccountDetails(state) {
            return state.accountDetails;
        },

        getInvoiceDetails(state) {
            return state.invoiceDetails;
        },

        getResourceUrl(state) {
            return state.resourceUrl;
        },

        isAuthenticated(state) {
            return state.isAuthenticated;
        },

        getSessionId(state) {
            return state.sessionId;
        },

        getAccountNumber(state) {
            return state.accountNumber;
        },

        getStatementDate(state) {
            return state.statementDate;
        },

        getErrorMessage(state) {
            return state.errorMessage;
        },
    },

    actions: {
        async authenticateUser({ commit }) {
            return new Promise((resolve) => {
                let url = apiUrl
                    .concat('/login')
                    .concat("?username=")
                    .concat(this.state.loginCredentials.username)
                    .concat("&password=")
                    .concat(this.state.loginCredentials.password);

                this.state.sessionId = '';

                axios
                    .get(url)
                    .then((response) => {
                        commit('setSessionId', response.data);
                        this.state.isAuthenticated = true;
                        this.state.errorMessage = '';
                        resolve();
                    }).catch(() => {
                        this.state.isAuthenticated = false;
                        this.state.errorMessage = "Invalid username or password";
                        resolve();
                    });
            });
        },

        updateLoginCredentials(context, loginCredentials) {
            if ((loginCredentials.username !== "" || loginCredentials.username != null)
                && (loginCredentials.password !== "" || loginCredentials.password != null)) {
                context.commit('addLoginCredentials', loginCredentials);
            }
        },

        fetchAccountSummary({ commit }) {
            return new Promise((resolve) => {
                let url = apiUrl
                    .concat('/accountSummary')
                    .concat('?sessionId=')
                    .concat(this.state.sessionId);

                axios
                    .get(url)
                    .then((response) => {
                        let res = response.data[0];

                        if (response.data.length == 0) {
                            this.state.errorMessage = "No account details found for account with current username";
                            resolve();
                        }

                        commit('setAccountSummary', res);
                        commit('setAccountNumber', res.accountNumber)
                        resolve();
                    }).catch((error) => {
                        if (error.response) {
                            this.state.errorMessage = "Error fetching account summary from server";
                            resolve();
                        }
                    });
            });
        },

        fetchAccountDetails({ commit }) {
            return new Promise((resolve) => {

                let url = apiUrl
                    .concat('/accountDetail')
                    .concat('?sessionId=')
                    .concat(this.state.sessionId)
                    .concat('&accountNumber=')
                    .concat(this.state.accountNumber);

                axios
                    .get(url)
                    .then((response) => {
                        let res = response.data;
                        if (response.data === null || response.data == '') {
                            this.state.errorMessage = "Error retrieving account details from server";
                            resolve();
                        }

                        commit('setAccountDetails', res);
                        commit('setStatementDate', res.statementDate);
                        resolve();
                    }).catch((error) => {
                        if (error.response) {
                            this.state.errorMessage = "Error fetching account details from server";
                            resolve();
                        }
                    });
            });
        },

        fetchInvoiceDetails({ commit }) {
            return new Promise((resolve) => {
                let url = apiUrl
                    .concat('/invoiceDetail')
                    .concat('?sessionId=')
                    .concat(this.state.sessionId)
                    .concat('&accountNumber=')
                    .concat(this.state.accountNumber)
                    .concat('&statementDate=')
                    .concat(this.state.statementDate);

                axios
                    .get(url)
                    .then((response) => {
                        let res = response.data;
                        if (res === null || res == '') {
                            this.state.errorMessage = "Error retrieving invoice details from server";
                            resolve();
                        }
                        commit('setInvoiceDetails', res);
                        resolve();
                    }).catch((error) => {
                        if (error.response) {
                            this.state.errorMessage = "Error fetching invoice details from server";
                            resolve();
                        }
                    });
            });
        },

        updateAutheticationState(context, isAuthenticated) {
            context.commit('setAuthenticated', isAuthenticated);
        },

        resetState(context, state) {
            context.commit('reset', state);
        },

        async downloadAllDocuments() {
            try {
                const sessionId = this.state.sessionId;
                const accountNumber = this.state.accountNumber;
                const statementDate = this.state.statementDate;

                let url = 'https://cportal.hellermann.co.za/plugin/hellermann/zip';
                let params = {
                    sessionId: sessionId,
                    accountNumber: accountNumber,
                    statementDate: statementDate
                };

                const response = await axios.get(url, {
                    params: params,
                    responseType: 'blob'
                });

                const blob = new Blob([response.data], { type: 'application/zip' });
                const blobUrl = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = 'vp-documents.zip';
                link.click();
            } catch (error) {
                console.error('Error downloading documents:', error);
            }
        }
    },

    mutations: {
        addLoginCredentials(state, loginCredentials) {
            state.loginCredentials = loginCredentials;
        },

        setAccountDetails(state, accountDetails) {
            state.accountDetails = accountDetails;
        },

        setAccountSummary(state, accountSummary) {
            state.accountSummary = accountSummary;
        },

        setInvoiceDetails(state, invoiceDetails) {
            state.invoiceDetails = invoiceDetails;
        },

        setAuthenticated(state, authenticated) {
            state.isAuthenticated = authenticated
        },

        reset(state) {
            Object.assign(state, defaultState());
        },

        LOADER(state, loaded) {
            state.loader = loaded;
        },

        setSessionId(state, sessionId) {
            state.sessionId = sessionId;
        },

        setAccountNumber(state, accountNumber) {
            state.accountNumber = accountNumber;
        },

        setStatementDate(state, statementDate) {
            state.statementDate = statementDate;
        },

        setErrorMessage(state, errorMessage) {
            state.errorMessage = errorMessage;
        },
    }
});

export default store;
